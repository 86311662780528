<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <brand-card-map-scores />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <location-map-scores />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <date-map-score />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <map-score-card />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import BrandCardMapScores from '@/views/MapScores/Elements/BrandCard.vue'
import LocationMapScores from '@/views/MapScores/Elements/LocationCard.vue'
import MapScoreCard from '@/views/MapScores/Elements/ScoreCard.vue'
import DateMapScore from '@/views/MapScores/Elements/dateCard.vue'

export default {
  name: 'ItemFormMapScores',
  components: {
    DateMapScore,
    MapScoreCard,
    LocationMapScores,
    BrandCardMapScores,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['mapScores/dataItem']
    },
  },
}
</script>
